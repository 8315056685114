import React from 'react';

const Deauthed = () => {

    return ( 

    	<>
        <h1> Strava has been successfully deauthorized.</h1> 
        </>
    )
}

export default Deauthed;